<template>
    <div>
        <CCard>
            <CCardHeader>
                <strong>Información de Empresa </strong>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol lg="3" md="3" sm="12" xs="12">
                        <CInput
                        type="text"
                        label="RUC"
                        v-model="about.ruc"
                        maxlength="11"
                        >
                        </CInput>
                    </CCol>
                    <CCol lg="5" md="5" sm="12" xs="12">
                        <CInput
                        type="text"
                        label="Dirección"
                        v-model="about.address"
                        >
                        </CInput>
                    </CCol>
                    <CCol lg="4" md="4" sm="12" xs="12">
                        <CInput
                        type="text"
                        label="Referencia"
                        v-model="about.reference"
                        >
                        </CInput>
                    </CCol>
                    <CCol lg="3" md="3" sm="12" xs="12">
                        <CInput
                        type="text"
                        label="Telefono 1"
                        v-model="about.phone_1"
                        >
                        </CInput>
                    </CCol>
                    <CCol lg="3" md="3" sm="12" xs="12">
                        <CInput
                        type="text"
                        label="Telefono 2"
                        v-model="about.phone_2"
                        >
                        </CInput>
                    </CCol>
                    <CCol lg="3" md="3" sm="12" xs="12">
                        <CInput
                        type="text"
                        label="Email 1"
                        v-model="about.email_1"
                        >
                        </CInput>
                    </CCol>
                    <CCol lg="3" md="3" sm="12" xs="12">
                        <CInput
                        type="text"
                        label="Email 2"
                        v-model="about.email_2"
                        >
                        </CInput>
                    </CCol>
                    <!-- <CCol lg="3" md="3" sm="12" xs="12">
                        <cSelectForm
                            label="Seleccione un pais"
                            :options="data_countries"
                            placeholder="Escoja un pais"
                            :value.sync="about.country_id"
                            @change="mtd_select_country"
                        />
                    </CCol> -->
                    <CCol lg="3" md="3" sm="12" xs="12">
                        <cSelectForm
                            label="Seleccione una region"
                            :options="data_regions"
                            placeholder="Escoja una region"
                            :value.sync="about.region_id"
                            @change="mtd_select_region"
                        />
                    </CCol>
                    <CCol lg="3" md="3" sm="12" xs="12">
                        <cSelectForm
                            label="Seleccione una provincia"
                            :options="data_province_filter"
                            placeholder="Escoja una provincia"
                            :value.sync="about.province_id"
                            @change="mtd_select_province"
                        />
                    </CCol>
                    <CCol lg="3" md="3" sm="12" xs="12">
                        <cSelectForm
                            label="Seleccione un distrito"
                            :options="data_disctrict_filter"
                            placeholder="Escoja un ditrito"
                            :value.sync="about.district_id"
                        />
                    </CCol>
                    <CCol lg="12" md="12" sm="12" xs="12" style="padding-top:25px">
                        <button 
                            type="button" 
                            class="btn-sm btn-primary" 
                            @click="mtd_saveAbout"
                        >
                            <i class="fas fa-save"></i>&nbsp;Guardar Cambios
                        </button> 
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
    components: { cSelectForm },
    data() {
        return {
            prefix: "about",
            about: {
                id: "",
                ruc: "",
                address: "",
                reference: "",
                country_id: null,
                region_id: "",
                province_id: "",
                district_id: "",
                phone_1: "",
                phone_2: "",
                email_1: "",
                email_2: "",
            },
            data_countries:[],
            data_regions:[],
            data_provinces: [],
            data_province_filter:[],
            data_disctrict: [],
            data_disctrict_filter: [],
        }
    },
    created(){
        this.mtd_getdata();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtd_getdata: function () {
            this.get({
                url: this.$store.getters.get__url + "/" + this.prefix,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                // this.data_countries = response.data_countries;
                this.data_regions = response.data_regions;
                this.data_provinces = response.provinces;
                this.data_disctrict = response.data_disctrict;

                this.about = response.about;
                this.about.country_id = parseInt(response.about.country_id);
                this.about.region_id = parseInt(response.about.region_id);
                this.mtd_select_region();
                this.about.province_id = parseInt(response.about.province_id);
                this.mtd_select_province();
                this.about.district_id = parseInt(response.about.district_id);
            })
            .catch((errors) => { });
        },
        mtd_select_country: function(){
            this.about.region_id = "";
            this.about.district_id = "";
            this.about.province_id = "";
        },
        mtd_select_region: function(){
            this.data_province_filter = [];
            this.data_provinces.forEach(element => {
                let prov =
                this.about.region_id.length == 5
                    ? "0" + this.about.region_id
                    : this.about.region_id;
                if (element.region == prov) {
                    this.data_province_filter.push(element);
                }
            });
        },
        mtd_select_province: function(){
            this.data_disctrict_filter = [];
            this.data_disctrict.forEach((element) => {
                let prov =
                this.about.province_id.length == 5
                    ? "0" + this.about.province_id
                    : this.about.province_id;
                if (element.province == prov) {
                this.data_disctrict_filter.push(element);
                }
            });
        },
        mtd_saveAbout: function(){
            this.post({
                url: this.$store.getters.get__url+'/'+this.prefix+'/update',
                token: this.$store.getters.get__token,
                params: this.about
            })
            .then((response) => {
                console.log(response);
                if(response){
                    bus.$emit("alert", {
                        color: 'success',
                        message: 'CAMBIOS REALIZADOS CORRECTAMENTE',
                    });
                }
            })
            .catch((error) => {
                // console.log(error);
            })
        }
    }
}
</script>>

